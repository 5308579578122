<template>
  <div>
    <button-all-header
      :contentBtnAdd="'Thêm người lao động '"
      :arrayExcel="arrayExcel"
      :showBtnMultiDelete="showBtnMultiDelete"
      @clickDowloadSample="downloadExampleFile"
      @clickExportExcel="downloadExportFile($route.params.id)"
      @importFile="importFileExcel($event)"
      @clickDelete="deleteItems"
      @clickAdd="showModalCreate"
      @search="search($event)"
    />

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'name'">
          {{ props.row.fullName }}
        </span>
        <span v-else-if="props.column.field === 'gender'">
          {{ props.row.gender ? 'Nam' :'Nữ' }}
        </span>
        <span v-else-if="props.column.field === 'birthDay'">
          {{ props.row.birthDay|formatDateToDDMM }}
        </span>

        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'ActionFunction'">
          <span
            @click="showModalEdit(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Chỉnh sửa'"
              icon="EditIcon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Xóa'"
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
      </template>
    </vue-good-table>
    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />

    <edit-worker-accident
      :id="modalIdEdit"
      :title="'Thêm người lao động'"
      :dataDetail="dataDetail"
      @accept="handlerAccept"
    />

  </div>
</template>

<script>

import { VBTooltip } from 'bootstrap-vue'
import { formatDateToDDMM } from '@core/utils/filter'
import { mapActions } from 'vuex'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import EditWorkerAccident from './EditWorkerAccident.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    ButtonAllHeader,
    MyPagination,
    VueGoodTable,
    ConfirmModal,
    EditWorkerAccident,
  },

  filters: {
    formatDateToDDMM,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      totalRecord: 0,
      modalIdEdit: 'modal-edit',
      showBtnMultiDelete: false,
      deleteIds: [],
      dataList: [],
      confirmModalId: 'confirm-modal',
      urlQuery: {
        accidentStatisticalId: this.$route.params.id,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      arrayExcel: [
        'fullName',
        'socialInsuranceNumber',
        'gender',
        'birthDay',
        'typeOfProfession',
        'jobAge',
        'salary',
        'level',
        'levelJob',
        'contractType',
        'workPlace',
        'locationAccident',
        'hour',
        'injuryElement',
        'injuryReason',
        'isTraining',
        'accidentReason',
        'statusWorker',
        'absent',
        'healthCost',
        'salaryCost',
        'indemnifyCost',
        'anotherCost',
        'assetCost',
        'note',
      ],
      modalContent: '',
      columns: [
        {
          label: 'HỌ VÀ TÊN',
          field: 'name',
          sortable: false,
        },
        {
          label: 'GIỚI TÍNH',
          field: 'gender',
          sortable: false,
        },
        {
          label: 'NGÀY SINH',
          field: 'birthDay',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          sortable: false,
          width: '150px',
        },
      ],
      idDetail: null,
      dataDetail: {},
    }
  },
  created() {
    this.getList()
  },

  methods: {

    ...mapActions('accidentStatistical', [
      'downloadExportFile',
      'downloadExampleFile',
      'getApiExcel',
    ]),

    showModalCreate() {
      this.$bvModal.show(this.modalIdEdit)
    },

    async showModalEdit(id) {
      this.idDetail = id
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_ACCIDENT}${id}`)
      this.dataDetail = data
      this.$bvModal.show(this.modalIdEdit)
    },

    async getList() {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_ACCIDENT, {
        params: this.urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },
    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xoá người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      const payload = {
        ids: this.deleteIds,
        accidentStatisticalId: this.$route.params.id,
      }
      await axiosApiInstance.post(ConstantsApi.DELETE_ACCIDENT, payload).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getList(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.getList(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.getList(this.urlQuery)
      }
    },

    handlerAccept(val) {
      if (!this.idDetail) {
        this.addWorkerAccident(val)
      } else {
        this.editWorkerAccident(val)
      }
    },

    addWorkerAccident(val) {
      const payload = {
        ...val,
        jobAge: parseInt(val.jobAge, 10),
        salary: parseInt(val.salary, 10),
        hour: parseInt(val.hour, 10),
        absent: parseInt(val.absent, 10),
        healthCost: parseInt(val.healthCost, 10),
        salaryCost: parseInt(val.salaryCost, 10),
        indemnifyCost: parseInt(val.indemnifyCost, 10),
        anotherCost: parseInt(val.anotherCost, 10),
        assetCost: parseInt(val.assetCost, 10),
      }
      axiosApiInstance.post(ConstantsApi.ADD_ACCIDENT, payload).then(response => {
        this.$bvToast.toast('Thêm thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalIdEdit)
        this.getList()
      }).catch(e => {
        this.$bvToast.toast(e.response.errors[0], {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    editWorkerAccident(val) {
      const payload = {
        ...val,
        id: this.idDetail,
        jobAge: parseInt(val.jobAge, 10),
        salary: parseInt(val.salary, 10),
        hour: parseInt(val.hour, 10),
        absent: parseInt(val.absent, 10),
        healthCost: parseInt(val.healthCost, 10),
        salaryCost: parseInt(val.salaryCost, 10),
        indemnifyCost: parseInt(val.indemnifyCost, 10),
        anotherCost: parseInt(val.anotherCost, 10),
        assetCost: parseInt(val.assetCost, 10),
      }
      axiosApiInstance.post(ConstantsApi.EDIT_ACCIDENT, payload).then(response => {
        this.$bvToast.toast('Chỉnh sửa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalIdEdit)
        this.getList()
      }).catch(e => {
        this.$bvToast.toast(e.response.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    async importFileExcel(event) {
      if (event) {
        this.getValidata(event)
      }
    },
    async getValidata(dataInput) {
      const array = ['jobAge',
        'salary',
        'hour',
        'absent',
        'healthCost',
        'salaryCost',
        'indemnifyCost',
        'anotherCost',
        'assetCost',
      ]
      dataInput.forEach(element => {
        Object.entries(element).forEach(([key, value]) => {
          if (array.includes(key)) {
            if (!value || typeof value === 'string') {
              element[key] = 0
            }
          }
        })
      })
      const model = {
        accidentStatisticalId: this.$route.params.id,
        listExcel: dataInput,
        isValidate: true,
      }
      const { data } = await this.getApiExcel(model)
      if (data.status === 400) {
        this.$bvToast.toast('có lỗi xảy ra', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      } else {
        await this.$router.push({ name: 'accedent-statistical-import' })
      }
    },
  },
}
</script>

<style>

</style>
