<template>
  <div>
    <b-form ref="rule">
      <b-row>

        <b-col
          lg="3"
          xl="3"
        >
          <b-form-group>
            <label for="">Tên cơ quan <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="{required:'Tên cơ quan là bắt buộc'}"
            >
              <b-form-input
                v-model="dataInput.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>
        </b-col>
        <b-col
          lg="3"
          xl="3"
        >
          <b-form-group>
            <label for="abc">Năm báo cáo<span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="year"
              rules="required"
              :custom-messages="{required:'Năm báo cáo là bắt buộc'}"
            >
              <b-form-input
                v-model="dataInput.year"
                type="number"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dataInput: {
      type: Object,
      default: _ => {},
    },
  },
}
</script>

<style>

</style>
