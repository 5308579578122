<template>
  <b-modal
    :id="id"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    cancel-title="Hủy bỏ"
    centered
    size="xl"
    no-close-on-backdrop
    :title="title"
    @ok="accept($event)"
    @hidden="resetData"
  >

    <validation-observer ref="rule">
      <b-form>
        <b-row>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Họ và tên <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="fullName"
                rules="required"
                :custom-messages="{required:'Họ và tên là bắt buộc'}"
              >
                <b-form-input
                  v-model="dataInput.fullName"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Mã số bảo hiểm xã hội</label>
              <validation-provider
                #default="{ errors }"
                name="socialInsuranceNumber"
                rules="alpha-dash"
                :custom-messages="{required:'Họ và tên là bắt buộc'}"
              >
                <b-form-input
                  v-model="dataInput.socialInsuranceNumber"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Giới tính</label>
              <div
                class="d-flex mt-1"
              >
                <b-form-radio
                  v-model="dataInput.gender"
                  :value="true"
                >
                  Nam
                </b-form-radio>
                <b-form-radio
                  v-model="dataInput.gender"
                  class="ml-1"
                  :value="false"
                >
                  Nữ
                </b-form-radio>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Ngày sinh</label>
              <date-time-picker
                v-model="dataInput.birthDay"
                :disabledInput="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Nghề nghiệp</label>
              <occupation-select
                v-model="dataInput.typeOfProfessionId"
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Tuổi nghề</label>
              <b-form-input
                v-model="dataInput.jobAge"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Mức lương</label>
              <b-form-input
                v-model="dataInput.salary"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Trình độ</label>
              <b-form-input
                v-model="dataInput.level"
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Bậc thợ</label>
              <b-form-input
                v-model="dataInput.levelJob"
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Loại hợp đồng lao động<span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="contractType"
                rules="required"
                :custom-messages="{required:'Loại hợp đồng lao động là bắt buộc'}"
              >
                <v-select
                  v-model="dataInput.contractType"
                  :reduce="title => title.contractType"
                  label="name"
                  :options="contractType || []"
                  :class="{'is-invalid':errors.length > 0 }"
                  :placeholder="'Lựa chọn loại hợp đồng lao động'"
                  :state="errors.length > 0 ? false:null"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Nơi làm việc</label>
              <b-form-input
                v-model="dataInput.workPlace"
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Nơi xảy ra tai nạn lao động</label>
              <b-form-input
                v-model="dataInput.locationAccident"
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Số giờ làm việc trong ca</label>
              <b-form-input
                v-model="dataInput.hour"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Yếu tố gây chấn thương<span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="injuryElementId"
                rules="required"
                :custom-messages="{required:'Yếu tố gây chấn thương là bắt buộc'}"
              >
                <v-select
                  v-model="dataInput.injuryElementId"
                  :reduce="title => title.id"
                  label="name"
                  :options="injuryElement || []"
                  :class="{'is-invalid':errors.length > 0 }"
                  :placeholder="'Lựa chọn yếu tố gây chấn thương'"
                  :state="errors.length > 0 ? false:null"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Loại chấn thương<span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="injuryReasonId"
                rules="required"
                :custom-messages="{required:'Loại chấn thương là bắt buộc'}"
              >
                <v-select
                  v-model="dataInput.injuryReasonId"
                  :reduce="title => title.id"
                  label="name"
                  :options="injuryReason || []"
                  :class="{'is-invalid':errors.length > 0 }"
                  :placeholder="'Lựa chọn loại chấn thương'"
                  :state="errors.length > 0 ? false:null"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Nguyên nhân gây tai nạn lao động<span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="accidentReasonId"
                rules="required"
                :custom-messages="{required:'Nguyên nhân gây tai nạn lao động là bắt buộc'}"
              >
                <v-select
                  v-model="dataInput.accidentReasonId"
                  :reduce="title => title.id"
                  label="name"
                  :options="accidentReason || []"
                  :class="{'is-invalid':errors.length > 0 }"
                  :placeholder="'Lựa chọn nguyên nhân gây tai nạn'"
                  :state="errors.length > 0 ? false:null"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Tình trạng gây thương tích<span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="statusWorker"
                rules="required"
                :custom-messages="{required:'Tình trạng gây thương tích là bắt buộc'}"
              >
                <v-select
                  v-model="dataInput.statusWorker"
                  :reduce="title => title.en"
                  label="vn"
                  :options="accidenType || []"
                  :class="{'is-invalid':errors.length > 0 }"
                  :placeholder="'Lựa chọn tình trạng gây thương tích'"
                  :state="errors.length > 0 ? false:null"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Số ngày nghỉ phép</label>
              <b-form-input
                v-model="dataInput.absent"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Chi phí y tế</label>
              <b-form-input
                v-model="dataInput.healthCost"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Chi phí trả lương trong thời gian điều trị</label>
              <b-form-input
                v-model="dataInput.salaryCost"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Chi phí bồi thường</label>
              <b-form-input
                v-model="dataInput.indemnifyCost"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Chi phí khác</label>
              <b-form-input
                v-model="dataInput.anotherCost"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <label>Thiệt hại tài sản</label>
              <b-form-input
                v-model="dataInput.assetCost"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="6"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="dataInput.isTraining"
                class="mt-3"
              >
                Đã huấn luyện ATVSLĐ
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label>Ghi chú</label>
              <b-form-textarea
                v-model="dataInput.note"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BRow,
  BCol,
  BFormRadio,
  BFormCheckbox,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaDash } from '@validations'
import vSelect from 'vue-select'
import OccupationSelect from '@/views/management-workers/occupation-list/pages/components/OccupationSelect.vue'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    OccupationSelect,
    vSelect,
    DateTimePicker,
  },

  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },

    dataDetail: {
      type: Object,
      default: _ => {},
    },
  },

  data() {
    return {
      dataInput: {
        accidentStatisticalId: this.$route.params.id,
        fullName: '',
        socialInsuranceNumber: '',
        gender: true,
        birthDay: null,
        typeOfProfessionId: '',
        jobAge: 0,
        salary: 0,
        level: '',
        levelJob: '',
        contractType: '',
        workPlace: '',
        locationAccident: '',
        hour: 0,
        injuryElementId: '',
        injuryReasonId: '',
        isTraining: true,
        accidentReasonId: '',
        statusWorker: '',
        absent: 0,
        healthCost: 0,
        salaryCost: 0,
        indemnifyCost: 0,
        anotherCost: 0,
        assetCost: 0,
        note: '',
      },

      contractType: [],
      injuryElement: [],
      injuryReason: [],
      accidentReason: [],
      accidenType: [
        {
          vn: 'Chết',
          en: 'Death',
        },
        {
          vn: 'Bị thương nặng',
          en: 'Serious',
        },
        {
          vn: 'Bị thương nhẹ',
          en: 'Minor',
        },
      ],
    }
  },

  watch: {
    dataDetail(val) {
      if (val) {
        this.dataInput.fullName = val.fullName
        this.dataInput.socialInsuranceNumber = val.socialInsuranceNumber
        this.dataInput.gender = val.gender
        this.dataInput.birthDay = val.birthDay
        this.dataInput.typeOfProfessionId = val.typeOfProfessionId
        this.dataInput.jobAge = val.jobAge
        this.dataInput.salary = val.salary
        this.dataInput.level = val.level
        this.dataInput.levelJob = val.levelJob
        this.dataInput.contractType = val.contractType
        this.dataInput.workPlace = val.workPlace
        this.dataInput.locationAccident = val.locationAccident
        this.dataInput.hour = val.hour
        this.dataInput.injuryElementId = val.injuryElementId
        this.dataInput.injuryReasonId = val.injuryReasonId
        this.dataInput.isTraining = val.isTraining
        this.dataInput.accidentReasonId = val.accidentReasonId
        this.dataInput.statusWorker = val.statusWorker
        this.dataInput.absent = val.absent
        this.dataInput.healthCost = val.healthCost
        this.dataInput.salaryCost = val.salaryCost
        this.dataInput.indemnifyCost = val.indemnifyCost
        this.dataInput.anotherCost = val.anotherCost
        this.dataInput.assetCost = val.assetCost
        this.dataInput.note = val.note
      }
    },
  },

  created() {
    this.getContractType()
    this.getInjuryElement()
    this.getInjuryReason()
    this.getAccidentReason()
  },

  methods: {
    resetData() {
      this.dataInput = {
        accidentStatisticalId: this.$route.params.id,
        fullName: '',
        socialInsuranceNumber: '',
        gender: true,
        birthDay: null,
        typeOfProfessionId: '',
        jobAge: 0,
        salary: 0,
        level: '',
        levelJob: '',
        contractType: '',
        workPlace: '',
        locationAccident: '',
        hour: 0,
        injuryElementId: '',
        injuryReasonId: '',
        isTraining: true,
        accidentReasonId: '',
        statusWorker: '',
        absent: 0,
        healthCost: 0,
        salaryCost: 0,
        indemnifyCost: 0,
        anotherCost: 0,
        assetCost: 0,
        note: '',
      }
    },

    async getContractType() {
      const { data } = await axiosApiInstance.get(ConstantsApi.CONTRACT_TYPE, {
        params: { countryId: -1 },
      })
      this.contractType = data?.data
    },
    async getInjuryElement() {
      const { data } = await axiosApiInstance.get(ConstantsApi.INJURY_ELEMENT)
      this.injuryElement = data?.data
    },
    async getInjuryReason() {
      const { data } = await axiosApiInstance.get(ConstantsApi.INJURY_REASON)
      this.injuryReason = data?.data
    },
    async getAccidentReason() {
      const { data } = await axiosApiInstance.get(ConstantsApi.ACCIDENT_REASON)
      this.accidentReason = data?.data
    },

    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },

  },
}
</script>

<style>

</style>
