<template>
  <div class="">
    <my-tabs
      :listTabs="listTabs"
      defaultActive="info"
      typeCss="active-full"
      @setActive="setActive"
    />

    <div class="page-container-table">

      <validation-observer ref="tabAccount">
        <infor-general
          v-if="isRendered('info')"
          :dataInput="dataInput"
          class="tab-pane ml-1"
          :class="{ active: activeTab === 'info' }"
        />

        <worker-accident
          v-if="isRendered('worker')"
          class="tab-pane"
          :class="{ active: activeTab === 'worker' }"
        />

      </validation-observer>
      <div class="d-flex ml-1 mt-1">

        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="handlerAccidentStatistical($event)"
        >
          Lưu lại
        </b-button>

        <b-button
          variant="outline-primary"
          type="reset"
          @click="cancel"
        >
          Quay lại
        </b-button>
      </div>
    </div>

  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import InforGeneral from './InfoGeneral.vue'
import WorkerAccident from './WorkerAccident.vue'
import MyTabs from '@/components/tabs/MyTabs.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    MyTabs,
    InforGeneral,
    WorkerAccident,
    ValidationProvider,
    ValidationObserver,
    BButton,
  },

  data() {
    return {
      dataInput: {
        name: '',
        year: '',
      },
      activeTab: null,
      listTabs: [
        {
          key: 'info',
          title: 'Thông tin chung',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'worker',
          title: 'Người lao động bị tai nạn',
          icon: 'UserIcon',
          isRendered: false,
          isDisabled: true,
        },
      ],
      dataDetail: {},
    }
  },
  watch: {
    dataDetail(val) {
      if (val) {
        this.dataInput.name = val.name
        this.dataInput.year = val.year
      }
    },
  },
  created() {
    if (this.activeTab === null) {
      this.activeTab = 'info'
      this.listTabs[0].isRendered = true
    }
    if (this.$route.params.id) {
      this.getDataDetail()
      this.listTabs[1].isDisabled = false
    }
  },
  methods: {
    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
    },

    async getDataDetail() {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL}${this.$route.params.id}`)
      this.dataDetail = data
    },

    async handlerAccidentStatistical(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.tabAccount.validate().then(async success => {
        if (success) {
          if (!this.$route.params.id) {
            this.addAccident()
          } else {
            this.editAccident()
          }
        }
      })
    },

    addAccident() {
      const payload = {
        ...this.dataInput,
        year: parseInt(this.dataInput.year, 10),
      }
      axiosApiInstance.post(ConstantsApi.ADD, payload).then(response => {
        this.$bvToast.toast('Thêm thống kê thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        if (response?.data?.data?.id) {
          this.$router.push({ name: 'accedent-statistical-edit', params: { id: response?.data?.data?.id } })
          this.listTabs[1].isDisabled = false
        }
      }).catch(e => {
        this.$bvToast.toast(e.response.data.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    editAccident() {
      const payload = {
        ...this.dataInput,
        year: parseInt(this.dataInput.year, 10),
        id: this.$route.params.id,
      }
      axiosApiInstance.put(ConstantsApi.EDIT, payload).then(response => {
        this.$bvToast.toast('chỉnh sửa thống kê thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$router.push({ name: 'accedent-statistical' })
      }).catch(e => {
        this.$bvToast.toast(e.response.data.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    cancel() {
      this.$router.push({ name: 'accedent-statistical' })
    },
  },
}
</script>

<style>

</style>
